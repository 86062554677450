<template>
  <div class="v-item-card-in-slider-wrapper v-h-100">
    <div
      v-if="product.ProductApiType === ProductApiType.Incorrect"
      class="v-menu-card v-error-color"
      v-html="translate('productInListPage.incorrect')"
    />
    <div
      v-else
      class="v-menu-card v-h-100"
      data-test-id="menu-card"
    >
      <div class="v-nomenclature-card">
        <div class="v-item-card-in-slider v-h-100">
          <div class="v-item-card-in-slider--image">
            <arora-nuxt-image
              disable-lazy
              :image="
                isSmall && imageExists(product.ProductMobileImage)
                  ? product.ProductMobileImage
                  : product.SmallImage
              "
              :image-type="
                isSmall && imageExists(product.ProductMobileImage) ? 'ProductMobile' : 'ProductSmall'
              "
              :alt="product.Name"
            />
          </div>
          <div class="v-item-card-in-slider--right-part v-d-flex v-flex-column">
            <div
              class="v-item-card-in-slider--title v-font-weight-700 v-mb-xs"
              v-html="sanitize(product.Name)"
            />
            <div
              v-if="!isSmall"
              class="v-body-text-color-light v-mb-xxs"
            >
              <menu-description :product-in-list="product" />
            </div>
            <div class="v-item-card-in-slider--price v-d-flex">
              <menu-product-in-list-price-and-button
                :card-click="cardClick"
                :is-recommendation="isRecommendation"
                :product-in-list="product"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProductInList } from '~types/menuStore'

import { useWindowSize } from '@arora/common'

import { ProductApiType } from '~api/consts'

const { product } = defineProps<{
  product: ProductInList
  isRecommendation?: boolean
}>()

const menuStore = useMenuStore()

const { sanitize, translate } = useI18nSanitized()
const { makeDefaultOption, openProductPopup } = useProduct()
const { imageExists } = useImageInfo()
const { isSmall } = useWindowSize()

const cardClick = async (): Promise<void> => await openProductPopup(product)

onMounted(() => {
  if (!menuStore.SelectedModifiersPerProduct.get(product.ID))
    menuStore.SelectedModifiersPerProduct.set(product.ID, {})

  makeDefaultOption(product)
})
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-item-card-in-slider-wrapper {
  width: 380px;

  @include mixins.sm {
    width: 180px;
  }
}

.v-item-card-in-slider {
  display: flex;
  flex-direction: row;
  border-radius: variables.$BorderRadius;
  overflow: hidden;

  @include mixins.sm {
    flex-direction: column;
  }

  &--image {
    width: 140px;
    flex: 0 0 140px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    user-select: none;

    @include mixins.md {
      flex: 175px;
      width: 175px;
    }

    @include mixins.sm {
      width: 100%;
      height: auto;
      flex: 120px;
      max-height: 120px;
    }

    .v-img-fluid {
      height: 100%;
      object-fit: cover;
    }
  }

  &--right-part {
    padding: 16px;
    height: 100%;
    flex: 0 0 calc(100% - 140px);
    width: calc(100% - 140px);

    .v-line-clamp-menu {
      -webkit-line-clamp: 2;
      line-clamp: 2;
    }

    @include mixins.md {
      flex: 0 0 calc(100% - 175px);
      width: calc(100% - 175px);

      .v-line-clamp-menu {
        -webkit-line-clamp: 1;
        line-clamp: 1;
      }
    }

    @include mixins.sm {
      width: 100%;
      flex: 0 0 calc(100% - 120px);
      height: calc(100% - 120px);
    }
  }

  &--title {
    font-size: 1.25rem;
    font-weight: 600;
    user-select: none;
  }

  &--price {
    margin: auto 0 0;
    font-size: 1.25rem;

    justify-content: space-between;
    align-items: baseline;
    flex-direction: row;
    flex-wrap: wrap;

    @include mixins.sm {
      flex-direction: column;
      gap: 0.75rem;
      color: variables.$BodyTextColorLight;

      .v-elements-overlay {
        width: 100%;
      }
      .v-btn {
        width: 100%;
      }
    }
  }
}
</style>
